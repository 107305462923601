<template lang="pug">
.page
  .tips 选择你要登录的商场帐号
  .marketList
    .marketItem(v-for="i in marketList" @click="handlePickMarket(i)" :class='{isPC:!isMobile}')
      img.logo(:src="i.commercialPlazaLogoUrl")
      .name {{i.commercialPlazaName}}
  .loadMore(@click="load") {{loadMsg}}

</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { getCache, updateCache } from '@/provider/cache'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'
const { fetch, router } = inject('global')

const marketList = ref([])
let isLoading = ref(false)
let isMore = ref(true)
let pagenation = ref({
  page: 1,
  size: 10
})

let loadMsg = computed(() => {
  if (isLoading.value) return '加载中...'
  if (isMore.value) return '点我加载更多'
  return '没有更多了'
})

function getData(page) {
  pagenation.value.page = page
  isLoading.value = true
  fetch
    .get('/boom-center-admin-service/app/commercialPlazaUserAssoc/pageByUserId', {
      params: {
        size: pagenation.value.size,
        page: pagenation.value.page
      }
    })
    .then(res => {
      marketList.value = res.list
      if (pagenation.value.page * pagenation.value.size >= res.total) {
        isMore.value = false
      }
    })
    .catch(res => {
      isMore.value = false
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handlePickMarket(val) {
  if (!val.commercialPlazaId) return
  fetch
    .get(
      `/boom-center-admin-service/app/commercialPlazaUserAssoc/switchCommercialPlazaId?commercialPlazaId=${val.commercialPlazaId}`
    )
    .then(res => {
      updateCache('currentMarketId', val.commercialPlazaId, { expireTime: 0 })
      currentMarketId.value = val.commercialPlazaId
      updateCache('currentMarketInfo', val, { expireTime: 0 })
      router.replace('/home')
    })
    .catch(err => {
      console.log(err)
    })
}

function load() {
  if (!isMore.value) return
  getData(pagenation.value.page + 1)
}

onMounted(() => {
  getData(1)
})
</script>

<style lang="less" scoped>
.tips {
  text-align: center;
  padding: 20px 0;
}
.marketList {
  display: flex;
  flex-wrap: wrap;
  .marketItem {
    .trans();
    flex-grow: 1;
    flex-basis: 400px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    &.isPC {
      width: calc(33% - 20px);
      flex-basis: auto;
      flex-grow: unset;
    }
    &:hover {
      background: var(--color-primary);
      color: white;
    }
    .logo {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
}

.loadMore {
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
</style>
